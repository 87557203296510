<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">Total Nilai PO *</label>
        <v-money class="w-full" :value="data.nilai_total" disabled/>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="ml-1 text-xs">DPP *</label>
        <v-money class="w-full" :value="dpp" disabled/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPN Jenis *</label>
        <vs-select autocomplete v-model="data.ppn_jenis" @input="onChangePpnJenis" class="w-full">
          <vs-select-item v-for="(item, index) in listPpnJenis" :key="index" :value="item.id" :text="item.nama"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPN *</label>
        <v-input-percentage :total="dpp" :percentage.sync="data.ppn_persen" :amountage.sync="data.ppn_jml" :disabled="data.ppn_jenis === 'TANPA PPN'"/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPh Jenis *</label>
        <vs-select class="w-full" autocomplete :value="data.pph_key" @input="onChangePphJenis">
          <vs-select-item :value="null" text="Tanpa PPh"/>
          <vs-select-item v-for="(item, index) in listPphJenis" :key="index" :value="item.key" :text="item.alias + ' - ' + (item.is_percentage ? `${item.value}%` : `Rp${idr(item.value)}`)"/>
        </vs-select>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPh *</label>
        <v-input-percentage class="w-full" :total="dpp" :percentage.sync="data.pph_persen" :amountage.sync="data.pph_jml" :disabled="!data.pph_key"/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">COA Credit *</label>
        <vs-select class="w-full" autocomplete v-model="data.id_cara_bayar_coa_credit">
          <vs-select-item v-for="(it, index) in caraBayarsCoaKredit" :key="index" :value="it.id" :text="it.nama"/>
        </vs-select>
      </div>
    </div>

    <!--termin-->
    <vs-divider class="mt-10">Detail Termin</vs-divider>
    <div class="vx-row mt-10 mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <div class="flex flex-wrap justify-between items-center mb-1">
          <div class="mb-4 md:mb-0 mr-4">
            <div class="flex space-x-3">
              <vs-button color="success" icon-pack="feather" icon="icon-plus" :disabled="terminSummary.totalPersen >= 100" @click="addRow" class="py-2 px-3">Add</vs-button>
              <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="data.items.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
              <vs-checkbox size="small" class="text-sm" v-model="data.is_input_termin_by_percentage">Input by percentage</vs-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-table :data="terminItems" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">Termin</vs-th>
        <vs-th class="whitespace-no-wrap">Tgl Due</vs-th>
        <vs-th class="whitespace-no-wrap">Persen Bayar</vs-th>
        <vs-th class="whitespace-no-wrap">Jumlah</vs-th>
        <vs-th class="whitespace-no-wrap">DPP</vs-th>
        <vs-th class="whitespace-no-wrap">PPN</vs-th>
        <vs-th class="whitespace-no-wrap">PPh</vs-th>
        <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index">
          <vs-td>
            <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
          </vs-td>
          <vs-td>{{ item.no_termin }}</vs-td>
          <vs-td><flat-pickr v-model="item.tgl_due"></flat-pickr></vs-td>
          <vs-td>
            <v-money v-if="$data.data.is_input_termin_by_percentage" class="w-24" theme="theme4" v-model="item.persen" :danger="terminSummary.totalPersen > 100"/>
            <p v-else class="text-sm text-right">{{ item.persen | idr }}</p>
          </vs-td>
          <vs-td class="text-right">
            <v-money v-if="!$data.data.is_input_termin_by_percentage" class="w-40" theme="theme4" v-model="item.jml_total"/>
            <p v-else class="text-sm text-right">{{ item.jml_total | idr }}</p>
          </vs-td>
          <vs-td class="text-right">{{ item.dpp | idr }}</vs-td>
          <vs-td class="text-right">{{ item.ppn_jml | idr }}</vs-td>
          <vs-td class="text-right">{{ item.pph_jml | idr }} </vs-td>
          <vs-td><vs-input v-model="item.keterangan" type="text"/></vs-td>
        </vs-tr>

        <!--footer-->
        <vs-tr v-if="terminItems.length > 0">
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td class="text-right" :class="{'text-danger': $data.data.is_input_termin_by_percentage && terminSummary.totalPersen > 100 }">{{ terminSummary.totalPersen | idr }}%</vs-td>
          <vs-td class="text-right" :class="{'text-danger': !$data.data.is_input_termin_by_percentage && terminSummary.totalJumlah > $data.data.nilai_total }">{{ terminSummary.totalJumlah | idr }}</vs-td>
          <vs-td class="text-right">{{ terminSummary.totalDpp | idr }}</vs-td>
          <vs-td class="text-right"> {{ terminSummary.totalPpn | idr }}</vs-td>
          <vs-td class="text-right"> {{ terminSummary.totalPph | idr }}</vs-td>
          <vs-td></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import SystemParameterRepository from '@/repositories/general/system-parameter-repository'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VInputPercentage from '@/views/components/v-input-percentage/VInputPercentage'
import constant from '@/constant/constant'
import moment from 'moment'

export default {
  name: 'TabPembayaran',
  props: {
    initData: { default: null, type: Object } // for edit
  },
  components: {
    flatPickr,
    VMoney,
    ValidationErrors,
    VInputPercentage
  },
  mounted () {
    this.getAllJenisPph()
    this.getAllCaraBayarCoaKredit()
  },
  watch: {
    initData (newVal, oldVal) {
      if (!_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.data = _.cloneDeep(newVal)
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    },
    tabBarang (value) {
      this.data.nilai_total = value.totalNilaiPo
    }
  },
  computed: {
    errors () {
      return this.$store.state.proyek.poAdd.errors.tabPembayaran.messages
    },
    tabHeader () {
      return this.$store.state.proyek.poAdd.tabHeader
    },
    tabBarang () {
      return this.$store.state.proyek.poAdd.tabBarang
    },
    dpp () {
      const isWithPpn = this.data.ppn_jenis === constant.PPN_JENIS.WITH_PPN
      if (isWithPpn) {
        const pembagi = (100 + this.data.ppn_persen) / 100
        return this.data.nilai_total / pembagi
      }
      return this.data.nilai_total
    },
    terminItems () {
      return _.map(this.data.items, item => {
        const isInputByPercentage = this.data.is_input_termin_by_percentage
        const percent = isInputByPercentage ? item.persen : (item.jml_total / this.data.nilai_total * 100)
        if (isInputByPercentage) {
          item.jml_total = this.data.nilai_total * (percent / 100)
        } else {
          item.persen = percent
        }
        item.dpp = this.dpp * (percent / 100)
        item.ppn_persen = this.data.ppn_persen
        item.ppn_jml = this.data.ppn_jml * (percent / 100)
        item.pph_persen = this.data.pph_persen
        item.pph_jml = this.data.pph_jml * (item.persen / 100)
        return item
      })
    },
    terminSummary () {
      return {
        totalPersen: _.sumBy(this.terminItems, item => item.persen),
        totalJumlah: _.sumBy(this.terminItems, item => item.jml_total),
        totalDpp: _.sumBy(this.terminItems, item => item.dpp),
        totalPpn: _.sumBy(this.terminItems, item => item.ppn_jml),
        totalPph: _.sumBy(this.terminItems, item => item.pph_jml)
      }
    }
  },
  data () {
    return {
      caraBayarsCoaKredit: [],
      listPphJenis: [],
      listPpnJenis: [
        { id: constant.PPN_JENIS.NO_PPN, nama: 'Tanpa PPN' },
        { id: constant.PPN_JENIS.WITH_PPN, nama: 'Dengan PPN' }
      ],
      data: {
        is_input_termin_by_percentage: 1,
        nilai_total: 0,
        ppn_jenis: constant.PPN_JENIS.NO_PPN,
        id_cara_bayar_coa_credit: null,
        items: [],
        deletedIds: []
      }
    }
  },
  methods: {
    getAllCaraBayarCoaKredit () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'PO' })
      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayarsCoaKredit = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllJenisPph () {
      const params = { group: 'PPH' }
      SystemParameterRepository.getAll(params)
        .then(response => {
          this.listPphJenis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onChangePpnJenis (value) {
      const isWithPpn = value === constant.PPN_JENIS.WITH_PPN
      if (isWithPpn) {
        const defaultPpnPercent = this.$systemParam('ppn_persen')
        this.data.ppn_persen = defaultPpnPercent
        this.data.ppn_jml = this.dpp * (defaultPpnPercent / 100)
        this.data.ppn_key = 'ppn_persen'
      } else {
        this.data.ppn_persen = null
        this.data.ppn_jml = null
        this.data.ppn_key = null
      }
    },

    onChangePphJenis (pphKey) {
      // without pph
      if (_.isNil(pphKey)) {
        this.data.pph_key = null
        this.data.pph_nama = null
        this.data.pph_persen = null
        this.data.pph_jml = null
        return
      }

      const pphJenis = _.find(this.listPphJenis, item => item.key === pphKey)
      this.data.pph_nama = pphJenis.alias
      this.data.pph_key = pphJenis.key
      if (pphJenis.is_percentage) {
        this.data.pph_persen = pphJenis.value
        this.data.pph_jml = this.dpp * (pphJenis.value / 100)
      } else {
        this.data.pph_persen = (pphJenis.value / this.dpp) * 100
        this.data.pph_jml = pphJenis.value
      }
    },

    addRow () {
      const itemsLength = this.data.items.length
      const prevItemTglDue = itemsLength === 0 ? null : this.data.items[(itemsLength === 1 ? 0 : itemsLength - 1)].tgl_due
      const row = {
        uuid: uuid(),
        no_termin: this.data.items.length + 1,
        persen: this.data.is_input_termin_by_percentage ? (100 - this.terminSummary.totalPersen) : 0,
        jml_total: !this.data.is_input_termin_by_percentage ? (this.data.nilai_total - this.terminSummary.totalJumlah) : 0,
        tgl_due: prevItemTglDue ? moment(prevItemTglDue).add(1, 'M').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        jml_bayar: 0
      }
      this.data.items.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.items, item => item.uuid === uuid)
      const item = this.data.items[index]
      if (item.id) {
        this.data.deletedIds.push(item.id)
      }
      this.data.items.splice(index, 1)
      this.updateNoTermin()
    },

    clearRows () {
      const deletedIds = _.map(_.filter(this.data.items, item => item.id), it => it.id)
      this.deletedIds = [...this.data.deletedIds, ...deletedIds]
      this.data.items = []
    },

    updateNoTermin () {
      this.data.items = _.map(this.data.items, (item, index) => {
        item.no_termin = index + 1
        return item
      })
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep({
        ...value,
        dpp: this.dpp,
        items: this.terminItems
      })
      this.$store.commit('proyek/poAdd/SET_TAB_PEMBAYARAN', payload)
    }, 500),

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    prev () {
      this.$store.commit('proyek/poAdd/SET_ACTIVE_TAB', 1)
    }
  }
}
</script>
